<script>
import { EPMC_ROOT } from '@/config'
import {
  fetchAuthorAff,
  fetchAuthorProfile,
  fetchAuthorPublicOrcidPublications,
  fetchAuthorPublications,
  fetchAuthorYearlyCitations,
} from '@/api'
import { registerMatomoEvent } from '@/helpers/matomo'
import WindowResize from '@/helpers/window-resize'
import showCitationGraph from '@/helpers/show-citation-graph'
import {
  Action,
  Chart,
  ContentSection,
  FeaturedNumber,
  List,
  Loading,
  NotificationList,
  SharePanel,
} from 'epmc-patterns/components/v2'
import { Dropdown } from 'epmc-patterns/components/v3'
import AuthorProfilePublication from '@/templates/author_profile/AuthorProfilePublication'

const show = 10

// for rendertron
const botRequest = window.location.search.indexOf('client=bot') !== -1

export default {
  metaInfo() {
    return {
      title: this.authorName,
      titleTemplate: '%s - Author profile - Europe PMC',
      meta: [{ name: 'description', content: this.authorName }],
    }
  },
  filters: {
    formatFeaturedNumberText(number) {
      return number.number === 1
        ? number.belowText
        : number.belowText.replace('<br>', 's<br>')
    },
  },
  components: {
    Action,
    AuthorProfilePublication,
    Chart,
    ContentSection,
    Dropdown,
    FeaturedNumber,
    List,
    Loading,
    NotificationList,
    SharePanel,
  },
  mixins: [WindowResize],
  props: {
    orcid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      screenWidth: window.innerWidth,
      // use orcid with all letters capital for later use
      authorId: this.orcid.toUpperCase(),

      schemaAuthorList: {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: [],
      },

      // used in the author profile
      authorName: '',
      authorAff: [],
      showMoreAff: false,

      // used in the featured numbers
      featuredNumbers: [
        { number: 0, belowText: 'Publication<br>in ORCID' },
        { number: 0, belowText: 'Publication<br>in Europe PMC' },
        { number: 0, belowText: 'Free full text article<br>in Europe PMC' },
        { number: 0, belowText: 'Open citation<br>in Europe PMC' },
      ],

      // used in the author citation graph
      status: '',
      authorPubChartLabels: [],
      authorPubChartDatasets: [],
      authorPubChartOptions: {
        title: {
          display: true,
          fontSize: 18,
          text: 'Items published and open citations received each year',
        },
        legend: {
          position: 'bottom',
          reverse: true,
        },
        scales: {
          yAxes: [
            {
              id: 'A',
              position: 'left',
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'Publications each year',
                fontSize: 14,
              },
            },
            {
              id: 'B',
              position: 'right',
              scaleLabel: {
                display: true,
                labelString: 'Times cited each year',
                fontSize: 14,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
            },
          ],
        },
        maintainAspectRatio: false,
      },

      sortOptions: [
        {
          text: 'Times cited (most)',
          value: 'citedByCount,1',
          selected: true,
        },
        {
          text: 'Date published (most recent)',
          value: 'firstPublicationDate,1',
          selected: false,
        },
      ],
      authorPublications: [],
      showAll: false,
      show,
      shown: false,
    }
  },
  computed: {
    notificationList() {
      const list = []
      if (this.status === 'no-show') {
        list.push('no-show')
      }
      if (this.featuredNumbers[1].number === 1) {
        list.push('claim-another')
      } else {
        list.push('')
      }
      return list
    },
  },
  created() {
    // update the author profile
    fetchAuthorProfile(this.authorId)
      .then((response) => {
        if (response) {
          const { givenNames, familyName, affiliation } = response
          this.authorName = (givenNames || '') + ' ' + (familyName || '')
          this.authorName = this.authorName.trim() || this.authorId
          if (affiliation) {
            this.authorAff.push(affiliation)
            return Promise.resolve(affiliation)
          }
        }
      })
      .then((affiliation) => {
        fetchAuthorAff(this.authorId).then((response) => {
          if (response) {
            const affiliations = response
            const index = affiliation ? affiliations.indexOf(affiliation) : -1
            if (index !== -1) {
              affiliations.splice(index, 1)
            }
            this.authorAff = this.authorAff.concat(affiliations)
            this.showMoreAff = botRequest
          }
        })
      })

    // update the orcid featured number
    fetchAuthorPublicOrcidPublications(this.authorId).then((response) => {
      this.featuredNumbers[0].number = response.querySelectorAll('group').length
    })

    Promise.all([
      fetchAuthorPublications(this.authorId),
      fetchAuthorYearlyCitations(this.authorId),
    ]).then((response) => {
      if (response.length === 2) {
        // update other featured numbers and the data used in the author publication list
        this.featuredNumbers[1].number = response[0].hitCount

        this.authorPublications = response[0].resultList
          ? response[0].resultList.result
          : []

        const schemaList = this.authorPublications.map((pub, i) => ({
          '@type': 'ListItem',
          position: i + 1,
          url: `${EPMC_ROOT}article/${pub.source}/${pub.id}`,
        }))
        this.schemaAuthorList['itemListElement'] = schemaList

        this.featuredNumbers[2].number = this.authorPublications.filter(
          (pub) => pub.inEPMC === 'Y'
        ).length
        this.featuredNumbers[3].number =
          this.authorPublications.length > 0
            ? this.authorPublications
                .map((pub) => pub.citedByCount)
                .reduce((acc, val) => acc + val)
            : 0

        // update the author citation graph
        // update labels
        const years = this.authorPublications
          .map((pub) => pub.firstPublicationDate.split('-')[0])
          .concat(response[1].map((pub) => pub.id.pubyear))
        this.authorPubChartLabels = [...new Set(years)].sort((a, b) => a - b)

        // update datasets
        let abstractYearlyCount = {}
        let fulltextYearlyCount = {}
        let licensedFulltextYearlyCount = {}
        let preprintYearlyCount = {}
        let citationYearlyCount = {}
        this.authorPubChartLabels.forEach((year) => {
          abstractYearlyCount[year] = 0
          fulltextYearlyCount[year] = 0
          licensedFulltextYearlyCount[year] = 0
          preprintYearlyCount[year] = 0
          citationYearlyCount[year] = 0
        })
        this.authorPublications.forEach((pub) => {
          const year = pub.firstPublicationDate.split('-')[0]
          const hasFulltext =
            pub.inEPMC === 'Y' || pub.hasBook === 'Y' || pub.source === 'NBK'
          if (pub.source === 'PPR') {
            preprintYearlyCount[year]++
          } else {
            if (hasFulltext && pub.isOpenAccess === 'N') {
              fulltextYearlyCount[year]++
            } else if (!hasFulltext) {
              abstractYearlyCount[year]++
            }
            if (pub.isOpenAccess === 'Y') {
              licensedFulltextYearlyCount[year]++
            }
          }
        })
        response[1].forEach((pub) => {
          citationYearlyCount[pub.id.pubyear] += pub.citingArticlesCount
        })
        citationYearlyCount = Object.values(citationYearlyCount)
        this.status = showCitationGraph(citationYearlyCount)
          ? 'show'
          : 'no-show'
        if (this.status === 'show') {
          abstractYearlyCount = Object.values(abstractYearlyCount)
          fulltextYearlyCount = Object.values(fulltextYearlyCount)
          licensedFulltextYearlyCount = Object.values(
            licensedFulltextYearlyCount
          )
          preprintYearlyCount = Object.values(preprintYearlyCount)
          this.authorPubChartDatasets.push(
            {
              label: 'Open citations of all articles',
              yAxisID: 'B',
              data: citationYearlyCount,
              type: 'line',
            },
            {
              label: 'Preprint',
              yAxisID: 'A',
              data: preprintYearlyCount,
              backgroundColor: '#79B4DE',
              borderColor: '#79B4DE',
              hoverBackgroundColor: '#b6d6ed',
            },
            {
              label: 'Free full text and licensed',
              yAxisID: 'A',
              data: licensedFulltextYearlyCount,
            },
            {
              label: 'Free full text',
              yAxisID: 'A',
              data: fulltextYearlyCount,
            },
            {
              label: 'Abstract',
              yAxisID: 'A',
              data: abstractYearlyCount,
            }
          )

          this.status = 'update'
        }
      }
      this.showAll = botRequest
    })
  },
  methods: {
    downloadChart() {
      const download = document.getElementById('download')
      const image = document
        .getElementById('citationGraph')
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream')
      download.setAttribute('href', image)
      download.setAttribute('download', this.authorName + '.png')
      download.click()
    },
    onPubListFilterSelected(option) {
      const arr = option.value.split(',')
      const prop = arr[0]
      const op = arr[1]
      this.authorPublications.sort((a, b) => {
        const compare =
          prop === 'firstPublicationDate'
            ? b[prop].localeCompare(a[prop])
            : b[prop] - a[prop] === 0
            ? a.firstPublicationDate.localeCompare(b.firstPublicationDate)
            : b[prop] - a[prop]
        return compare * op
      })
    },
    registerMatomoEvent,
  },
}
</script>
<template>
  <div id="author-profile-page">
    <script type="application/ld+json">
      {{ schemaAuthorList }}
    </script>
    <div id="author-profile-page-content">
      <div class="grid-row">
        <div class="col-16">
          <div class="author-sect">
            <div class="flex">
              <div
                class="author-info"
                itemscope
                itemtype="http://schema.org/Person"
              >
                <div class="inline-block">
                  <h1 itemprop="name">
                    {{ authorName }}
                  </h1>
                  <a
                    itemprop="mainEntityOfPage"
                    class="orcid-link"
                    :href="'//orcid.org/' + authorId"
                    ><i class="fab fa-orcid" /><span class="alt-text"
                      >Visit ORCID profile</span
                    ></a
                  >
                  <a
                    itemprop="mainEntityOfPage"
                    class="impactstory-logo"
                    :href="'//profiles.impactstory.org/u/' + authorId"
                    ><img
                      src="@/assets/impactstory-logo.png"
                      alt="Visit Impact Story"
                  /></a>
                </div>
                <p v-if="authorAff.length > 0">
                  {{ authorAff[0] }}
                </p>
                <template v-if="authorAff.length > 1">
                  <action icon-pos="right" @click="showMoreAff = !showMoreAff">
                    Show other affiliations ({{ authorAff.length - 1 }})
                    <i
                      slot="icon"
                      :class="[
                        'fas',
                        showMoreAff ? 'fa-caret-down' : 'fa-caret-right',
                      ]"
                    />
                  </action>
                  <p
                    v-for="aff in authorAff.slice(1)"
                    v-show="showMoreAff"
                    :key="aff"
                  >
                    {{ aff }}
                  </p>
                </template>
              </div>
              <share-panel :title="authorName" label="Share this profile" />
            </div>
          </div>

          <div class="featured-numbers">
            <featured-number
              v-for="number in featuredNumbers"
              :key="number.belowText"
              :number="number.number"
              :below-text="number | formatFeaturedNumberText"
              size="large"
            />
          </div>

          <div v-if="status !== 'no-show'" class="download-btn">
            <button @click="downloadChart">
              <i class="fas fa-download" /> Download chart
            </button>
            <a id="download" />
          </div>

          <loading v-if="status === ''" />
          <div v-show="status === 'update'" class="chart-container">
            <chart
              id="citationGraph"
              type="bar"
              :labels="authorPubChartLabels"
              :datasets="authorPubChartDatasets"
              :options="authorPubChartOptions"
              :update="status === 'update'"
              aria-label="Author publication chart"
              role="img"
            />
          </div>
          <notification-list :notification-list="notificationList">
            <template slot-scope="{ notification }">
              <template v-if="notification === 'no-show'">
                No graph available until there is 1 citation in more than 1
                year.
              </template>
              <template v-else-if="notification === 'claim-another'">
                &#8202;<a href="/orcid/import">Claim another article</a>
                to your ORCID iD to generate a chart of your publications.
              </template>
              <template v-else>
                Want your own profile?
                <a
                  href="/orcid/import"
                  @click="
                    registerMatomoEvent(
                      'Author profile',
                      'Full text',
                      'Link your articles to your ORCID'
                    )
                  "
                  >Link your articles to your ORCID</a
                >
                in 2 simple steps.
              </template>
            </template>
          </notification-list>
        </div>
      </div>
      <section id="claimedArticles" class="grid-row">
        <content-section
          v-if="featuredNumbers[1].number"
          :section="{
            heading: 'h2',
            title:
              'Publications listed in Europe PMC (' +
              featuredNumbers[1].number +
              ')',
          }"
        >
          <label for="pub-sort-options" class="semi-bold">Sort by:</label>
          <dropdown
            id="pub-sort-options"
            :options="sortOptions"
            @onOptionSelected="onPubListFilterSelected"
          />

          <list
            :list="authorPublications.slice(0, show)"
            :separator-below="true"
          >
            <author-profile-publication
              slot-scope="{ item }"
              :publication="item"
            />
          </list>
          <template v-if="featuredNumbers[1].number > show">
            <div v-if="shown || showAll" v-show="showAll">
              <list
                :list="
                  authorPublications.slice(show, featuredNumbers[1].number)
                "
                :separator-above="showAll"
                :separator-below="true"
              >
                <author-profile-publication
                  slot-scope="{ item }"
                  :publication="item"
                />
              </list>
            </div>
            <div class="show-all">
              <action
                icon-pos="right"
                @click="
                  showAll = !showAll
                  shown = true
                  registerMatomoEvent(
                    'Author profile',
                    'Full text',
                    'Click any show more links'
                  )
                "
              >
                {{
                  showAll
                    ? 'Show less'
                    : 'Show all ' + featuredNumbers[1].number + ' publications'
                }}<i
                  slot="icon"
                  :class="['fas', showAll ? 'fa-caret-up' : 'fa-caret-right']"
                />
              </action>
            </div>
          </template>
        </content-section>
      </section>
    </div>
  </div>
</template>
<style lang="scss">
#author-profile-page {
  #author-profile-page-content {
    padding-bottom: $base-unit * 6;
    .author-sect {
      margin-top: $base-unit * 11;
      background-color: lighten($epmc-darker-grey, 64%);
      padding: ($base-unit * 4) ($base-unit * 4) ($base-unit * 2);
      .flex {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .author-info {
          margin-bottom: $base-unit * 2;
          .inline-block {
            * {
              display: inline-block;
            }
          }
          .orcid-link {
            font-size: $base-unit * 7;
            margin-left: $base-unit;
          }
          .impactstory-logo {
            display: inline-block;
            margin-left: $base-unit;
            vertical-align: $base-unit * -0.25;
            img {
              height: $base-unit * 6.75;
            }
          }
          .impactstory-logo:hover,
          .orcid-link:hover {
            opacity: 0.8;
          }
        }
      }
      .share-panel {
        margin-bottom: $base-unit * 2;
      }
    }
    .chart-container {
      position: relative;
      height: $base-unit * 165;
      @media screen and (max-width: $breakpoint-large) {
        height: $base-unit * 135;
      }
      @media screen and (max-width: $breakpoint-small) {
        height: $base-unit * 120;
      }
      @media screen and (max-width: $breakpoint-extra-small - 100) {
        height: $base-unit * 100;
      }
    }
    .featured-numbers {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      & > * {
        flex-basis: 20%;
        margin-top: $base-unit * 9;
        @media screen and (max-width: ($breakpoint-extra-small)) {
          flex-basis: 40%;
          flex-shrink: 0;
        }
        @media screen and (max-width: $breakpoint-smallest - 40) {
          flex-basis: 100%;
        }
      }
    }
    .download-btn {
      margin-top: $base-unit * 9;
      text-align: center;
    }
    .notification-list {
      margin-top: $base-unit * 9;
    }
    .content-section {
      margin-top: $base-unit * 10;
      margin-bottom: $base-unit * 13;
      .dropdown-v3 {
        margin-bottom: $base-unit * 8;
        width: $base-unit * 70;
      }
      .show-all {
        margin-top: $base-unit * 2;
        text-align: center;
      }
    }
  }
}
</style>
