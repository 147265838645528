<script>
import { fetchYearlyCitations } from '@/api'
import showCitationGraph from '@/helpers/show-citation-graph'
import { Chart, Notification } from 'epmc-patterns/components/v2'
import Citation from '@/templates/Citation'

export default {
  components: { Chart, Citation, Notification },
  props: {
    publication: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      labels: [],
      datasets: [],
      options: {
        legend: {
          display: false,
        },
      },
      status: '',
    }
  },
  created() {
    const { source, id } = this.publication

    fetchYearlyCitations(source, id).then((response) => {
      const counts = response.map((pub) => {
        return pub.citingArticlesCount
      })

      // update the chart data only when there is a citation in more than one year
      this.status = showCitationGraph(counts) ? 'show' : 'no-show'
      if (this.status === 'show') {
        // update labels
        this.labels = response.map((pub) => pub.id.pubyear)

        // update datasets based on whether the most recent year that has citations is the current year
        const numberOfYears = this.labels.length
        const mostRecentYear = this.labels[numberOfYears - 1]
        const currentYear = new Date().getFullYear()
        const label = 'Citation'
        if (mostRecentYear === currentYear) {
          const counts2 = counts.map((count, index) => {
            if (index !== counts.length - 1 && index !== counts.length - 2) {
              return NaN
            } else {
              return count
            }
          })
          counts[counts.length - 1] = NaN

          this.datasets.push(
            {
              label,
              data: counts,
            },
            {
              label,
              data: counts2,
              borderDash: [2, 2],
            }
          )
        } else {
          this.datasets.push({
            label,
            data: counts,
          })
        }

        // update options
        this.options.title = {
          display: true,
          fontSize: 12,
          text: 'Cited ' + this.publication.citedByCount + ' times in EPMC',
        }

        this.status = 'update'
      }
    })
  },
}
</script>
<template>
  <div class="author-profile-publication grid-row">
    <div class="chart col-5 col-m-7">
      <chart
        v-show="status === 'update'"
        :labels="labels"
        :datasets="datasets"
        :options="options"
        :update="status === 'update'"
        :aria-label="
          'Chart for article ' + publication.source + '/' + publication.id
        "
        role="img"
      />
      <notification v-if="status === 'no-show'">
        No graph available until there is a citation in more than one year
      </notification>
    </div>
    <citation class="col-11 col-m-9" :citation="publication" />
  </div>
</template>
<style scoped lang="scss">
.author-profile-publication .chart {
  margin-bottom: $base-unit * 2.5;
}
</style>
