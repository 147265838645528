export default function showCitationGraph(citations) {
  let show = false
  const length = citations.length
  let yearWithCitationsCount = 0
  for (let i = 0; i < length; i++) {
    if (citations[i]) {
      yearWithCitationsCount++
    }
    if (yearWithCitationsCount === 2) {
      show = true
      break
    }
  }
  return show
}
